import axios from "axios";
import React, { useState } from "react";
import "./blogUpdate.css";

export default function BlogUpdate() {
  const [Heading, setHeading] = useState("");
  const [SubPara, setSubPara] = useState("");
  const [FullPara_1, setFullPara_1] = useState("");
  const [FullPara_2, setFullPara_2] = useState("");
  const [FullPara_3, setFullPara_3] = useState("");
  const [FullPara_4, setFullPara_4] = useState("");
  const [Date, setDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://www.api.machmart.in/api/UploadBlogData", {
        Heading,
        SubPara,
        FullPara_1,
        FullPara_2,
        FullPara_3,
        FullPara_4,
        Date,
      });
      alert("Blog saved successfully!");
      setHeading("");
      setSubPara("");
      setFullPara_1("");
      setFullPara_2("");
      setFullPara_3("");
      setFullPara_4("");
      setDate("");
    } catch (error) {
      console.error("Error saving item:", error);
      alert("Error saving item. Please try again later.");
    }
  };

  return (
    <>
      <section className="Blog_Update_Section">
        <div className="mainDivision_BlogUpdate">
          <label htmlFor="Heading">Heading</label>
          <textarea
            onChange={(e) => setHeading(e.target.value)}
            type="text"
            name="Heading"
            id="Heading"
          />
          <label htmlFor="SubPara">Supportive Paragraph</label>
          <textarea
            onChange={(e) => setSubPara(e.target.value)}
            type="text"
            name="SubPara"
            id="SubPara"
          />
          <label htmlFor="FullPara_1">Full Paragraph 1</label>
          <textarea
            onChange={(e) => setFullPara_1(e.target.value)}
            type="text"
            name="FullPara_1"
            id="FullPara_1"
          />
          <label htmlFor="FullPara_2">Full Paragraph 2</label>
          <textarea
            onChange={(e) => setFullPara_2(e.target.value)}
            type="text"
            name="FullPara_2"
            id="FullPara_2"
          />
          <label htmlFor="FullPara_3">Full Paragraph 3</label>
          <textarea
            onChange={(e) => setFullPara_3(e.target.value)}
            type="text"
            name="FullPara_3"
            id="FullPara_3"
          />
          <label htmlFor="FullPara_4">Full Paragraph 4</label>
          <textarea
            onChange={(e) => setFullPara_4(e.target.value)}
            type="text"
            name="FullPara_4"
            id="FullPara_4"
          />
          <label htmlFor="Date">Date</label>
          <textarea
            onChange={(e) => setDate(e.target.value)}
            type="text"
            name="Date"
            id="Date"
          />
          <div className="btnDiv_updateBlog">
            {" "}
            <button onClick={handleSubmit}>Submit</button>
          </div>{" "}
        </div>
      </section>
    </>
  );
}
