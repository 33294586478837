import React from "react";

export default function Fromowner() {
  return (
    <>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
        className="fromOwnerSection"
      >
        <div className="mainDivision_FromOwner">This page is Coming Soon</div>
      </section>
    </>
  );
}
