import React, { useEffect, useState } from "react";
import Hero from "../Components/Hero";
import axios from "axios";
import "./updates.css";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";

export default function Updates() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://www.api.machmart.in/api/blogData"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
    setLoading(true);

    fetchData();
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="UpdatesSection">
          <Hero
            Heading="Explore Insights & Inspiration: Mach Mart's Blog Hub"
            subHeading="Welcome to Mach Mart's Blog Hub, your go-to destination for insightful articles and inspiring content. Dive into a treasure trove of knowledge as we share valuable insights, industry trends, and practical tips to enhance your experience with Mach Mart products and services. Whether you're a seasoned enthusiast or a curious newcomer, our blog is packed with resources to fuel your passion and drive your success. Stay updated with the latest news, guides, and stories curated just for you. Explore, learn, and be inspired with Mach Mart's blog!"
          />

          <div className="mainDivUpdatesSection">
            {data.map((item) => {
              return (
                <>
                  <section className="blogsSections">
                    <h1>{item.Heading}</h1>
                    <p>{item.SubPara}</p>
                    <div className="btnDiv_blogs">
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={item.Heading}
                      >
                        <button>Read More</button>
                      </Link>
                    </div>
                  </section>
                </>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
}
