import React, { useState } from "react";
import "./navbar.css";
import brandLogo from "../Assets/logoMachMart.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [ind_menu, setInd_Menu] = useState("div_menu_Indistries_hide");
  const [ind_menu_2, setInd_Menu_2] = useState("div_menu_2_Indistries_hide");
  const [megaMenu, setMegaMenu] = useState("MegaMenu_Navbar_Hide");

  const [fixNavClass, setFixNavClass] = useState("HideNavBar_Fixed");

  // Mobile Menu

  const [classNameMobileMenu, setClassNameMobileMenu] = useState(
    "mobileMenu_Hide_Navbar"
  );

  const [classNameMobileMenuIcon, setClassNameMobileMenuIcon] = useState(
    "fa-solid fa-bars fa-sm fa-xl"
  );

  // End of Mobile Menu

  window.addEventListener(
    "scroll",
    function () {
      setMegaMenu("MegaMenu_Navbar_Hide");

      if (window.scrollY < 200) {
        setFixNavClass("HideNavBar_Fixed");
      } else {
        setFixNavClass("Navbar_Section_Lower_New");
      }
    },
    false
  );
  return (
    <>
      <section className="Navbar_MainSection">
        <section className="Navbar_Section_Upper">
          <div className="mainDivision_NavbarSection_Upper">
            {" "}
            <div className="textMachinery_NavbarUpperSection">
              <h3>All Types of Wood Working Machinery Available Here</h3>
            </div>
            <div className="socialmediaIcons_Navbar">
              <a
                target="blank"
                href={"https://www.instagram.com/machmartjaipur/"}
              >
                {" "}
                <i className="fa-brands fa-instagram fa-lg"></i>{" "}
              </a>
              <a
                href="https://www.instagram.com/machmartjaipur/"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-facebook fa-lg"></i>{" "}
              </a>
              <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                {" "}
                <i className="fa-brands fa-whatsapp fa-lg"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-linkedin fa-lg"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-pinterest fa-lg"></i>{" "}
              </a>
              <a
                href="https://www.youtube.com/channel/UCi95P-Zo5UscGHYq_0PF1hg"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-youtube fa-lg"></i>{" "}
              </a>
            </div>
          </div>
        </section>
        <section className="NavbarSection_Both">
          {" "}
          <section className="Navbar_Section_Middle">
            <div className="mainDivision_Navbar_Middle_Division">
              <div className="logoDiv_Navbar">
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/"}
                >
                  {" "}
                  <img src={brandLogo} alt="Mach Mart Logo" />
                </Link>
              </div>
              <div className="contactDetailsDiv_Navbar">
                <p>
                  <i className="fa-solid fa-location-dot"></i> Vishwakarma
                  Industrial Area, Jaipur, Rajasthan
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i>{" "}
                  <a href="tel:8503886992">8503886992</a>,{" "}
                  <a href="tel:9828259289">9828259289</a>
                </p>
              </div>
            </div>
          </section>
          <section className="Navbar_Section_Lower">
            <div className="mainDivision_Navbar_Section_Lower">
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/"}
                  >
                    Homepage
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setMegaMenu("MegaMenu_Navbar");
                  }}
                >
                  <Link>
                    Machinery <i className="fa-solid fa-angle-down"></i>{" "}
                  </Link>
                </li>
                <li
                  onMouseEnter={() => {
                    setInd_Menu("div_menu_Indistries");
                  }}
                  onMouseLeave={() => {
                    setInd_Menu("div_menu_Indistries_hide");
                  }}
                >
                  <Link>
                    Industries <i className="fa-solid fa-angle-down"></i>{" "}
                  </Link>
                  <div className={ind_menu}>
                    {" "}
                    <ul>
                      <li>
                        <Link
                          onClick={() => {
                            setInd_Menu("div_menu_Indistries_hide");
                          }}
                          to={"/woodworkingmachinery"}
                        >
                          Wood Working Machinery
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            setInd_Menu("div_menu_Indistries_hide");
                          }}
                          to={"/spiceprocessingmachinery"}
                        >
                          Spice Processing Machinery
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            setInd_Menu("div_menu_Indistries_hide");
                          }}
                          to={"/millplant"}
                        >
                          Mill Plants{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/updates"}
                  >
                    Updates
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/contact"}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/about"}
                  >
                    About
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </section>
      </section>

      {/* Navigation Bar Lower */}

      <section className={fixNavClass}>
        <div className="mainDivision_Navbar_Section_Lower_New">
          <div className="logoDiv_New">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/"}
            >
              {" "}
              <img src={brandLogo} alt="Mach Mart Logo" />
            </Link>
          </div>
          <div className="navbarDiv_New">
            <ul>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/"}
                >
                  Homepage
                </Link>
              </li>
              <li
                onClick={() => {
                  setMegaMenu("MegaMenu_Navbar");
                }}
              >
                <Link>
                  Machinery <i className="fa-solid fa-angle-down"></i>{" "}
                </Link>
              </li>
              <li
                onMouseEnter={() => {
                  setInd_Menu_2("div_menu_2_Indistries");
                }}
                onMouseLeave={() => {
                  setInd_Menu_2("div_menu_2_Indistries_hide");
                }}
              >
                <Link>
                  Industries <i className="fa-solid fa-angle-down"></i>{" "}
                </Link>
                <div className={ind_menu_2}>
                  {" "}
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          setInd_Menu("div_menu_Indistries_hide");
                        }}
                        to={"/woodworkingmachinery"}
                      >
                        Wood Working Machinery
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setInd_Menu("div_menu_Indistries_hide");
                        }}
                        to={"/spiceprocessingmachinery"}
                      >
                        Spice Processing Machinery
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setInd_Menu("div_menu_Indistries_hide");
                        }}
                        to={"/millplant"}
                      >
                        Mill Plants{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/updates"}
                >
                  Updates
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/contact"}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="contactSalesDiv_New">
            <Link to={"tel:8503886992"}>
              {" "}
              <button>
                {" "}
                <i className="fa-solid fa-phone"></i> Contact Sales
              </button>
            </Link>
          </div>
        </div>
      </section>
      {/* End of Navigation Bar Lower */}
      <section className={megaMenu}>
        <div className="mainDivision_Mega_Menu">
          <span
            onClick={() => {
              setMegaMenu("MegaMenu_Navbar_Hide");
            }}
          >
            {" "}
            <i className="fa-solid fa-chevron-left fa-xs"></i> Back
          </span>
          <div className="catDivs_MegaMenu">
            <h1>Wood Working Machines</h1>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Combined Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Surface Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Thickness Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Chain Mortiser
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Chisel Mortiser
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Vertical Band Saw
                </Link>
              </li>
            </ul>
          </div>
          <div className="catDivs_MegaMenu">
            <h1>Spice Processing Machines</h1>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/spiceprocessingmachinery"}
                >
                  Blower Pulverizer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/spiceprocessingmachinery"}
                >
                  Impact Pulverizer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/spiceprocessingmachinery"}
                >
                  Pulverizer with Air Lock
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/spiceprocessingmachinery"}
                >
                  Conveyor System
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/spiceprocessingmachinery"}
                >
                  Masala Mixer
                </Link>
              </li>
            </ul>
          </div>
          <div className="catDivs_MegaMenu">
            <h1>Mill Plant</h1>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/millplant"}
                >
                  Dall Mill Plant
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/millplant"}
                >
                  Atta Chakki Plant
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setMegaMenu("MegaMenu_Navbar_Hide");
                  }}
                  to={"/millplant"}
                >
                  Daliya Making Plant
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="MobileMenuNav">
        <div className="mainDiv_MobileMenuNav">
          <div className="logoDiv_MobileMenu_Nav">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                document.body.style.overflow = "auto";
              }}
              style={{ display: "flex" }}
              to={"/"}
            >
              {" "}
              <img src={brandLogo} alt="Mach Mart Logo" />
            </Link>{" "}
          </div>
          <div className="menuIconDiv_MobMenu_Nav">
            <i
              onClick={() => {
                if (classNameMobileMenu === "mobileMenu_Hide_Navbar") {
                  setClassNameMobileMenu("mobileMenu_View_Navbar");
                  document.body.style.overflow = "hidden";
                  setClassNameMobileMenuIcon("fa-solid fa-xmark fa-xl");
                } else {
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  document.body.style.overflow = "auto";
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                }
              }}
              className={classNameMobileMenuIcon}
            ></i>
          </div>
        </div>
      </section>

      <section className={classNameMobileMenu}>
        <div className="mainDivisionMobileMenu">
          <ul>
            <li>Welcome to Mach Mart</li>
            <li>
              <Link
                to={"/"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Homepage
              </Link>
            </li>
            <li>
              Machinery <i className="fa-solid fa-arrow-right-long"></i>
            </li>
            <li>
              <Link
                to={"/woodworkingmachinery"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Wood Working Machinery
              </Link>
            </li>{" "}
            <li>
              <Link
                to={"/spiceprocessingmachinery"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Spice Making Machinery
              </Link>
            </li>{" "}
            <li>
              <Link
                to={"/millplant"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Mill Plants
              </Link>
            </li>
            <li>
              <Link
                to={"/updates"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Updates
              </Link>
            </li>
            <li>
              <Link
                to={"/contact"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Contact{" "}
              </Link>
            </li>
            <li>
              <Link
                to={"/about"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                About{" "}
              </Link>
            </li>
          </ul>

          <div className="contactDiv_MobileMenu">
            <h4>Contact Details :-</h4>
            <h1>Mach Mart</h1>
            <p>Plot No. H726B, Road No. 9F2, Vishwakarma Industrial Area</p>
            <p>Jaipur, Rajasthan, India </p>
            <span className="span_FollowUs_MobileMenu">Follow Us On </span>{" "}
            <div className="socialMediaHandles">
              <a
                target="blank"
                href={"https://www.instagram.com/machmartjaipur/"}
              >
                {" "}
                <i className="fa-brands fa-instagram fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.instagram.com/machmartjaipur/"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-facebook fa-xl"></i>{" "}
              </a>
              <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                {" "}
                <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.youtube.com/channel/UCi95P-Zo5UscGHYq_0PF1hg"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-youtube fa-xl"></i>{" "}
              </a>
            </div>
            <span>
              Contact No.: <a href="tel:8503886992">8503886992</a>,{" "}
              <a href="tel:7849912491">7849912491</a>
            </span>
            <span>
              Email:{" "}
              <a href="mailto:sales@x360marketing.com">sales@machmart.in</a>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
