import React, { useEffect, useState } from "react";
import "./contact.css";
import Hero from "../Components/Hero";
import { BarLoader } from "react-spinners";

export default function Contact() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="ContactPageSection">
          <Hero
            Heading="Get in Touch: Contact Mach Mart, Wood Working Machines Manufacturers Today"
            subHeading="Ready to take your woodworking projects to the next level? Reach out to Mach Mart, premier manufacturers of woodworking machines, today. Our team is here to assist you with any inquiries, from product details to technical support. Whether you're a hobbyist or a professional, contact us for reliable solutions that elevate your craftsmanship and productivity."
          />
          <section className="getInTouch_Section">
            <div className="mainDivision_getInTouch_Section">
              <div className="gIT_divs">
                <i className="fa-solid fa-location-dot fa-2xl" />
                <span>Address</span>
                <span>Head Office</span>
                <p>
                  Mach Mart, Plot No. H726B. Road No. 9F2, Vishwakarma
                  Industrial Area, Jaipur, Rajasthan Pincode: 302013
                </p>
              </div>
              <div className="gIT_divs">
                <i className="fa-solid fa-phone fa-2xl" />
                <span>Phone</span>
                <span>
                  For Sales <a href="tel:8503886990"> 8503886992</a>
                </span>
                <span>
                  For Service <a href="tel:9828259289">9828259289</a>
                </span>
              </div>
              <div className="gIT_divs">
                <i className="fa-solid fa-envelope fa-2xl" />
                <span>Email</span>
                <span>
                  For Sales{" "}
                  <a href="mailto:sales@machmart.in">sales@machmart.in</a>
                </span>
                <span>
                  For Service{" "}
                  <a href="mailto:service@machmart.in">service@machmart.in</a>
                </span>
              </div>
            </div>
          </section>
          <section className="mapSection">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16301.650625355664!2d75.7814455377991!3d26.98865369379909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db352a58ad9a7%3A0xd3f9b9c797a27ce3!2sMach%20Mart!5e0!3m2!1sen!2sin!4v1712222464245!5m2!1sen!2sin"
              width="100%"
              height="1080px"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </section>
          <div className="imgDiv_mvvg_Section">
            <h1>Contact Us Now</h1>
            <p>
              At Mach Mart, we're your premier destination for wood working
              machinery. Whether you're a hobbyist or a professional, we've got
              the perfect tools for your projects. Need assistance or advice?
              Don't hesitate to get in touch with our expert team. We're
              dedicated to ensuring your woodworking endeavors are a cut above
              the rest. Reach out today and let's make your visions a reality.
            </p>
            <div className="btnsDiv_Hero">
              <a href={"tel:8503886992"}>
                {" "}
                <button className="callNowBTN_Hero">
                  <i className="fa-solid fa-phone fa-lg"></i> Call Now
                </button>
              </a>
              <a target="blank" href={"https://wa.me//918503886992"}>
                {" "}
                <button className="whatsAppNowBTN_Hero">
                  {" "}
                  <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
                </button>
              </a>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
