import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Updates from "./Pages/Updates";
import Fromowner from "./Pages/Fromowner";
import BlogDisplayPage from "./Pages/BlogDisplayPage";
import BlogUpdate from "./Pages/BlogUpdate";
import AllProductsPage from "./Pages/AllProductsPage";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/fromowner" element={<Fromowner />} />
          <Route path="/updates/:blogURL" element={<BlogDisplayPage />} />
          <Route path="/api/blogupdate/newblog" element={<BlogUpdate />} />
          <Route path=":categoryName" element={<AllProductsPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
