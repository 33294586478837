import React from "react";
import "./proCardHome.css";
export default function ProductCard_Home(props) {
  return (
    <>
      <section className="ProductCard_Home">
        <div className="mainDivision_ProCard_Home">
          <a target="blank" href={"https://wa.me//918503886992"}>
            <img src={props.ImageURL} alt={props.Product_Category} />
          </a>
          <h1>{props.Name}</h1>
          <h3> Category - {props.Product_Category}</h3>
          <div className="btnsDiv_pCard">
            <a href={"tel:8503886992"}>
              <button className="callNowBTN_Hero">
                <i className="fa-solid fa-phone fa-lg"></i> Call Now
              </button>
            </a>
            <a target="blank" href={"https://wa.me//918503886992"}>
              <button className="whatsAppNowBTN_Hero">
                <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
