import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductCard from "../Components/ProductCard";
import "./allpropage.css";
import { BarLoader } from "react-spinners";

export default function AllProducts_Page() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://www.api.machmart.in/api/productsData/get"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();

    setLoading(true);

    fetchData();
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const { categoryName } = useParams();

  let catName = "";

  if (categoryName === "woodworkingmachinery") {
    catName = "Wood Working Machinery";
  } else if (categoryName === "spiceprocessingmachinery") {
    catName = "Spice Processing Machinery";
  } else {
    catName = "Mill Plants";
  }

  console.log(categoryName);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="AllProductPage_Section">
          <div className="mainDivisionProductPage_Section">
            <span>
              {" "}
              <Link to={"/"}>Home</Link> / Machinery / {catName}
            </span>{" "}
            {data.map((item) => {
              if (categoryName === item.Cat_URL) {
                return (
                  <>
                    <ProductCard
                      Image={item.Image_URL}
                      Product_URL={item.Product_URL}
                      Product_Name={item.Product_Name}
                      Product_Small_Description={item.Product_Small_Description}
                      Product_Category={item.Product_Category}
                    />
                  </>
                );
              } else {
                return null;
              }
            })}
          </div>
        </section>
      )}
    </>
  );
}
