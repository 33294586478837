import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="FooterSection">
        <div className="mainDivision_FooterSection">
          <div className="navManu_Divs addressDivCom_Footer">
            <div className="addressDiv">
              <h1>Mach Mart</h1>
              <p>Plot No. H726B, Road No. 9F2, Vishwakarma Industrial Area</p>
              <p>Jaipur, Rajasthan, India </p>
              <span className="span_FollowUs">Follow Us On </span>{" "}
              <div className="socialMediaHandles_Footer">
                <a
                  target="blank"
                  href={"https://www.instagram.com/x360marketing/"}
                >
                  {" "}
                  <i className="fa-brands fa-instagram fa-xl"></i>{" "}
                </a>
                <a
                  href="https://www.instagram.com/x360marketing/"
                  target="blank"
                  to={"/"}
                >
                  {" "}
                  <i className="fa-brands fa-facebook fa-xl"></i>{" "}
                </a>
                <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                  {" "}
                  <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
                </a>
                <a
                  href="https://www.linkedin.com/company/x360-marketing"
                  target="blank"
                  to={"/"}
                >
                  {" "}
                  <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
                </a>
                <a
                  href="https://www.linkedin.com/company/x360-marketing"
                  target="blank"
                  to={"/"}
                >
                  {" "}
                  <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
                </a>
                <a
                  href="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
                  target="blank"
                  to={"/"}
                >
                  {" "}
                  <i className="fa-brands fa-youtube fa-xl"></i>{" "}
                </a>
              </div>
              <span>
                Contact No.: <a href="tel:8503886992">8503886992</a>,{" "}
                <a href="tel:7849912491">7849912491</a>
              </span>
              <span>
                Email:{" "}
                <a href="mailto:sales@x360marketing.com">sales@machmart.in</a>
              </span>
            </div>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Quick Links</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/"}
                >
                  Homepage
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/updates"}
                >
                  Updates
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/contact"}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Machinery</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Combined Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Surface Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Thickness Planer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Chain Mortiser
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/woodworkingmachinery"}
                >
                  Vertical Band Saw
                </Link>
              </li>
            </ul>
          </div>
          <div className="navManu_Divs">
            <ul>
              <li>Company Profile</li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About Company
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/fromowner"}
                >
                  From Owner/Founder
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/termsandconditions"}
                >
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="copyrightDiv_Footer">
        <div className="mainDivCopyright">
          {" "}
          <p>
            {" "}
            <i className="fa-regular fa-copyright"></i> Copyright 2023-2024 Mach
            Mart
          </p>
          <p>
            Website Designed and Maintained by{" "}
            <a target="blank" href="https://x360marketing.in/">
              X360 Marketing
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
