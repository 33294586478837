import React from "react";
import "./productCard.css";
import { Link } from "react-router-dom";

export default function ProductCard(props) {
  return (
    <>
      <section className="ProductCardSection">
        <div className="mainDivisionProductCardSection">
          <div className="imgDiv_productCard">
            <a target="blank" href={"https://wa.me//918503886992"}>
              <img src={props.Image} alt="Wood Working Machines" />
            </a>
          </div>
          <div className="contentDiv_productCard">
            <h1>{props.Product_Name}</h1>
            <p>{props.Product_Small_Description}</p>
            <span>Category - {props.Product_Category}</span>
            <div className="btnsDiv_pCard">
              <a href={"tel:8503886992"}>
                <button className="callNowBTN_Hero">
                  <i className="fa-solid fa-phone fa-lg"></i> Call Now
                </button>
              </a>
              <a target="blank" href={"https://wa.me//918503886992"}>
                <button className="whatsAppNowBTN_Hero">
                  <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
                </button>
              </a>
              <Link className="hideDivLink" to={"/updates"}>
                <button className="learnMore_BTN">
                  <i className="fa-solid fa-info fa-lg"></i> Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
