import React from "react";
import "./about.css";
import Hero from "../Components/Hero";
import missionImage from "../Assets/missionPNG.png";
import goalsImage from "../Assets/goalsPNG.png";
import visionImage from "../Assets/visionPNG.png";
import valuesImage from "../Assets/valuesPNG.png";

export default function About() {
  return (
    <>
      <section className="AboutPageSection">
        <Hero
          Heading="Mach Mart - Your Premier Woodworking Machines Manufacturers"
          subHeading="At Mach Mart, we're dedicated woodworking machine manufacturers, crafting precision tools to suit global needs. With decades of experience, we prioritize quality, innovation, and customer satisfaction. Whether you're a hobbyist or a professional, our reliable solutions ensure an enhanced woodworking journey. Join us and experience excellence in every project."
        />

        <section className="CompanyProfileSection">
          <div className="mainDivisionComProfile">
            <h1>1. Company Profile:</h1>
            <p>
              Mach Mart stands as a premier provider of woodworking machinery
              solutions, catering to the diverse needs of artisans, hobbyists,
              and industry professionals alike. With a commitment to quality and
              innovation, we offer a wide range of cutting-edge equipment and
              accessories designed to enhance precision and efficiency in
              woodworking projects of all scales. Whether you're crafting
              intricate furniture pieces or tackling large-scale construction
              projects, Mach Mart is your trusted partner for achieving
              exceptional results.
            </p>
            <h1>2. History:</h1>
            <p>
              Founded in 2020 by Jitendra Jangid, Mach Mart was born out of a
              passion for woodworking and a vision to elevate the industry
              standards. From its humble beginnings, the company has grown
              steadily, driven by a relentless pursuit of excellence and a
              customer-centric approach. Over the years, Mach Mart has
              established itself as a leading name in the woodworking machinery
              market, earning recognition for its superior products and
              unwavering commitment to customer satisfaction.
            </p>
            <h1>3. Established By:</h1>
            <p>
              Mach Mart was established by Jitendra Jangid, a visionary
              entrepreneur with a profound love for woodworking and a keen eye
              for innovation. With years of experience in the industry, Jitendra
              recognized the need for high-quality, reliable machinery that
              could empower woodworkers to unleash their creativity and achieve
              unparalleled precision in their craft. Guided by his passion and
              expertise, Jitendra founded Mach Mart with a mission to provide
              woodworkers with access to cutting-edge tools and unparalleled
              support, driving the industry forward one project at a time.
            </p>
            <h1>4. Our Commitment:</h1>
            <p>
              At Mach Mart, we are committed to delivering excellence in every
              aspect of our business. From the quality of our products to the
              efficiency of our services, we strive to exceed customer
              expectations at every turn. Our team of experts works tirelessly
              to innovate and improve, ensuring that our customers have access
              to the latest advancements in woodworking technology. With a focus
              on quality, innovation, and customer satisfaction, Mach Mart
              remains dedicated to empowering woodworkers worldwide to achieve
              their greatest ambitions.
            </p>
            <h1>5. Achievements:</h1>
            <p>
              Since its inception, Mach Mart has garnered numerous accolades and
              achievements, solidifying its reputation as a trailblazer in the
              woodworking machinery industry. From industry awards to glowing
              testimonials from satisfied customers, our achievements stand as a
              testament to our unwavering commitment to excellence. With each
              milestone reached, Mach Mart reaffirms its position as a leader in
              the field, inspiring confidence and trust among woodworkers
              worldwide.
            </p>
          </div>
        </section>

        <section className="mvvg_Section">
          <div className="mainDivision_mvvg_Section">
            <div className="leftDiv_mvvg">
              <h1>Our Mission, Vision, Values and Goals</h1>
              <p>
                At Mach Mart, our mission is to revolutionize the wood working
                machinery industry with innovative solutions tailored to our
                customers' needs. With a vision of becoming the go-to
                destination for premium woodworking equipment, we uphold values
                of quality, reliability, and customer satisfaction. Our goal is
                simple: to empower woodworkers of all levels with the tools and
                support they need to excel. Join us on this journey and
                experience woodworking excellence like never before.{" "}
              </p>
            </div>
            <div className="rightDiv_mvvg">
              <div className="mvvg_Divs">
                <img src={missionImage} alt="Mach Mart Mission" />
                <h1>Our Mission</h1>
                <p>
                  At Mach Mart, our mission is to revolutionize the wood working
                  machinery industry with innovative solutions tailored to our
                  customers' needs.
                </p>
              </div>
              <div className="mvvg_Divs">
                <img src={visionImage} alt="Mach Mart Vision" />
                <h1>Our Vision</h1>
                <p>
                  With a vision of becoming the go-to destination for premium
                  woodworking equipment, we aim to redefine excellence in the
                  field.
                </p>
              </div>
              <div className="mvvg_Divs">
                <img src={valuesImage} alt="Mach Mart Values" />
                <h1>Our Values</h1>
                <p>
                  We uphold values of quality, reliability, and customer
                  satisfaction in every aspect of our business operations.
                </p>
              </div>
              <div className="mvvg_Divs">
                <img src={goalsImage} alt="Mach Mart Goals" />
                <h1>Our Goals</h1>
                <p>
                  Our goal is simple: to empower woodworkers of all levels with
                  the tools and support they need to excel in their craft.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="imgDiv_mvvg_Section">
          <h1>Contact Us Now</h1>
          <p>
            At Mach Mart, we're your premier destination for wood working
            machinery. Whether you're a hobbyist or a professional, we've got
            the perfect tools for your projects. Need assistance or advice?
            Don't hesitate to get in touch with our expert team. We're dedicated
            to ensuring your woodworking endeavors are a cut above the rest.
            Reach out today and let's make your visions a reality.
          </p>
          <div className="btnsDiv_Hero">
            <a href={"tel:8503886992"}>
              {" "}
              <button className="callNowBTN_Hero">
                <i className="fa-solid fa-phone fa-lg"></i> Call Now
              </button>
            </a>
            <a target="blank" href={"https://wa.me//918503886992"}>
              {" "}
              <button className="whatsAppNowBTN_Hero">
                {" "}
                <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
