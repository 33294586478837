import React from "react";
import "./hero.css";

export default function Hero(props) {
  return (
    <>
      <section className="Hero_Section">
        <div className="mainDivision_HeroSection">
          <h1>{props.Heading}</h1>
          <p>
           {props.subHeading}
          </p>
          <div className="btnsDiv_Hero">
            <a href={"tel:8503886992"}>
              {" "}
              <button className="callNowBTN_Hero">
                <i className="fa-solid fa-phone fa-lg"></i> Call Now
              </button>
            </a>
            <a target="blank" href={"https://wa.me//918503886992"}>
              {" "}
              <button className="whatsAppNowBTN_Hero">
                {" "}
                <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
