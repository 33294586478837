import React from "react";
import "./terms.css";
import Hero from "../Components/Hero";

export default function TermsAndConditions() {
  return (
    <>
      <section className="TermsAndConditions_Section">
        <Hero
          Heading="Our Company's Terms and Conditions"
          subHeading="By agreeing to these terms and conditions, you acknowledge that you have read, understood, and accepted all provisions outlined herein. This agreement governs your use of our services, including any updates, modifications, or additions made thereafter. It is your responsibility to regularly review these terms for any changes. Failure to comply with these terms may result in the termination of your access to our services.          "
        />

        <div className="termsDiv">
          <ul>
            <li>
              <b>1. Machine Return Policy:</b> The machine(s) provided by{" "}
              <b>Mach Mart</b> are not eligible for return under any
              circumstances once they have been received by the customer.
            </li>
            <li>
              <b>2. Acceptance of Condition:</b> Upon receipt of the machine,
              the customer acknowledges and accepts its condition as described
              at the time of purchase.
            </li>
            <li>
              <b>3. No Refunds or Exchanges:</b> We do not offer refunds or
              exchanges for any machines sold.
            </li>
            <li>
              <b>4. Warranty Disclaimer:</b> Any warranties provided with the
              machine are void if the machine has been used or altered in any
              way.
            </li>
            <li>
              <b>5. Damage Responsibility:</b> The customer is responsible for
              any damage that occurs to the machine after it has been received.
            </li>
            <li>
              <b>6. Shipping Responsibility:</b> Any costs associated with
              shipping the machine to and from <b>Mach Mart</b> are the
              responsibility of the customer.
            </li>
            <li>
              <b>7. Inspection Upon Receipt:</b> Customers are encouraged to
              thoroughly inspect the machine upon receipt and report any damage
              or issues immediately.
            </li>
            <li>
              <b>8. No Rental Agreement:</b> The purchase of a machine from{" "}
              <b>Mach Mart</b> does not constitute a rental agreement, and the
              machine is not expected to be returned.
            </li>
            <li>
              <b>9. Legal Ownership:</b> Legal ownership of the machine
              transfers to the customer upon receipt, and the customer assumes
              all responsibility for its care and maintenance.
            </li>
            <li>
              <b>10. No Buyback Policy:</b> <b>Mach Mart</b> does not offer a
              buyback program for machines previously sold to customers.
            </li>
            <li>
              <b>11. Usage Limitations:</b> Customers are responsible for using
              the machine in accordance with all applicable laws and
              regulations.
            </li>
            <li>
              <b>12. Third-Party Responsibility:</b> Any third-party services or
              modifications made to the machine are the responsibility of the
              customer and do not affect the terms of this agreement.
            </li>
            <li>
              <b>13. Modification Policy:</b> Customers are prohibited from
              modifying the machine in any way that may void its warranty or
              alter its functionality.
            </li>
            <li>
              <b>14. Indemnification:</b> Customers agree to indemnify and hold
              <b>Mach Mart</b> harmless from any claims or damages arising from
              their use or ownership of the machine.
            </li>
            <li>
              <b>15. Governing Law:</b> This agreement shall be governed by and
              construed in accordance with the laws of Jurisdiction, without
              regard to its conflict of law provisions.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
