import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./blogDisplay.css";
import { BarLoader } from "react-spinners";

export default function BlogDisplayPage() {
  const [data, setData] = useState([]);
  const { blogURL } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://www.api.machmart.in/api/blogData"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
    setLoading(true);
    fetchData();
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="BlogDisplaySection">
          <div className="mainDivision_BlogDisplaySection">
            {data.map((item) => {
              if (item.Heading === blogURL) {
                return (
                  <>
                    <h4>
                      <Link to={"/updates"}>Updates</Link>
                      {" / "} {item.Heading}
                    </h4>
                    <h1>{item.Heading}</h1>
                    <h2>Posted on {item.Date}</h2>
                    <p>{item.FullPara_1}</p>
                    <p>{item.FullPara_2}</p>
                    <p>{item.FullPara_3}</p>
                    <p>{item.FullPara_4}</p>
                  </>
                );
              } else {
                return null;
              }
            })}
          </div>
        </section>
      )}
    </>
  );
}
