import React, { useEffect, useState } from "react";
import "./home.css";
import Hero from "../Components/Hero";
import axios from "axios";
import ProductCardHome from "../Components/ProductCardHome";
import { BarLoader } from "react-spinners";

export default function Home() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://www.api.machmart.in/api/productsData/get"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setLoading(true);

    fetchData();
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="HomepageSection">
          <Hero
            Heading="All Types of Wood Working Machines Available Here"
            subHeading=" At Mach Mart, we have all kinds of woodworking machines. We make it
            easy for you to find what you need, like table saws, jointers, and
            planers. Our machines are good quality and work well. Whether you're
            a beginner or an expert, we have the right tools to help you with
            your woodworking projects."
          />

          <section className="ProductsSection">
            <h1 className="mainHeading_Home">Wood Working Machines</h1>
            <div className="mainDivision_ProductSection">
              {data.map((item) => {
                if (
                  item.Product_Category === "Wood Working Machines" &&
                  (item.Product_Name === "Wood Combined Planer" ||
                    item.Product_Name === "Surface Planer" ||
                    item.Product_Name === "Thickness Planer" ||
                    item.Product_Name === "Vertical Band Saw" ||
                    item.Product_Name === "Chain Mortiser" ||
                    item.Product_Name === "Chisel Mortiser")
                ) {
                  return (
                    <>
                      <ProductCardHome
                        ImageURL={item.Image_URL}
                        Name={item.Product_Name}
                        Product_Category={item.Product_Category}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </section>

          {/* Contact Us Section Comp */}

          <div className="imgDiv_mvvg_Section">
            <h1>Contact Us Now</h1>
            <p>
              At Mach Mart, we're your premier destination for wood working
              machinery. Whether you're a hobbyist or a professional, we've got
              the perfect tools for your projects. Need assistance or advice?
              Don't hesitate to get in touch with our expert team. We're
              dedicated to ensuring your woodworking endeavors are a cut above
              the rest. Reach out today and let's make your visions a reality.
            </p>
            <div className="btnsDiv_Hero">
              <a href={"tel:8503886992"}>
                {" "}
                <button className="callNowBTN_Hero">
                  <i className="fa-solid fa-phone fa-lg"></i> Call Now
                </button>
              </a>
              <a target="blank" href={"https://wa.me//918503886992"}>
                {" "}
                <button className="whatsAppNowBTN_Hero">
                  {" "}
                  <i className="fa-brands fa-whatsapp fa-lg"></i> What's App
                </button>
              </a>
            </div>
          </div>

          {/* End of Contact Us Section Comp */}
        </section>
      )}
    </>
  );
}
